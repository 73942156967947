export const environment = {
  production: false,
  // api: 'https://sdc-v002.k8s.samsungospdev.com',
  // api: 'https://sdc-sad-eucentral1-v002.k8s.samsungospdev.com',
  api: 'https://sdcd.samsungospdev.com',
  apiGraphql: 'https://sdcd.samsungospdev.com/graphql',
  profileLink: 'https://heimdall-sad-eucentral1.samsungospdev.com/membership/auth/sign-in',
  documentation: 'https://documentd.samsungospdev.com',
  remotesignin: 'https://signins.samsungospdev.com',
  webhook: false,
  apiDashboard: true,
  showBusinessFields: true,
  saml: true,
};
